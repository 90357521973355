import React from 'react'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'
import wispeek from '../../assets/images/home/wispeek.png'
import { AnchorLink } from "gatsby-plugin-anchor-links"

// Logos partenaires
import partner2 from '../../assets/images/partner/partner2.png'
import partner8 from '../../assets/images/partner/partner8.png'
import partner9 from '../../assets/images/partner/partner9.png'
import partner12 from '../../assets/images/partner/partner12.png'
import partner14 from '../../assets/images/partner/partner14.png'
import partner15 from '../../assets/images/partner/partner15.png'
import partner19 from '../../assets/images/partner/partner19.png'
import partner21 from '../../assets/images/partner/partner21.png'
import partner23 from '../../assets/images/partner/partner23.png'


const Banner = () => {
    return (
        <div className="it-services-banner" style={{marginBottom:'50px'}}>
            <div className="container">
                <div className="row align-items-center pt-30" style={{justifyContent:'center'}}>
                    <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                        <ReactWOW delay='.2s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <img src={partner2} alt="partner" />
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                        <ReactWOW delay='.2s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <img src={partner8} alt="partner" />
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                        <ReactWOW delay='.3s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner9} alt="partner" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                        <ReactWOW delay='.6s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner12} alt="partner" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                        <ReactWOW delay='.2s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <img src={partner14} alt="partner" />
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                        <ReactWOW delay='.3s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner15} alt="partner" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <img src={partner19} alt="partner" />
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                        <ReactWOW delay='.3s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner21} alt="partner" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-1 col-3 col-sm-3 col-md-3">
                        <ReactWOW delay='.5s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={partner23} alt="partner" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>
                </div>
                <div className="row align-items-center pt-10">
                    <div className='col-lg-12 col-md-12 center'>
                        <h1 className='title-home' style={{fontSize:'2rem'}}>
                            Dispositif de signalement<br></br>
                            et de remontées d'informations
                        </h1>
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="project-start-image pt-30">
                                <img width='50%' src={wispeek} alt="wispeek mutli-devices" />
                            </div>
                        </ReactWOW>
                    </div>
                    <div className="col-lg-6 col-md-12 center pt-10">
                        <div className="banner-wrapper-content">
                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box">
                                    <p>Vous êtes salarié, collaborateur ?</p>
                                    <AnchorLink to="/#salaries" className="default-btn">
                                        <i className="bx bx-chevron-right"></i> 
                                        Mon espace wispeek <span></span>
                                    </AnchorLink>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 center pt-10">
                        <div className="banner-wrapper-content">
                            <ReactWOW delay='.1s' animation='fadeInRight'> 
                                <div className="btn-box">
                                    <p>Vous êtes référent, responsable hiérarchique ?</p>
                                    <Link to="/referents" className="default-btn btn-custom">
                                        <i className="bx bx-chevron-right"></i> 
                                        Espace référent <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner